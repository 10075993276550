import * as React from 'react';
import { BrowserRouter, Navigate, Outlet, Route, Routes as RoutesSwitch } from 'react-router-dom';

import { PlausibleGoals, Routes } from '../constants';
import { ApiEndpoints } from '../endpoints';

import { useStore as useStoreUserMe } from '../stores/UserMeStore';

import { AuthLoginCallbackPage } from './pages/AuthLoginCallbackPage';
import { CommandsPage } from './pages/CommandsPage';
import { ErrorPage } from './pages/ErrorPage';
import { FAQPage } from './pages/FAQPage';
import { HomePage } from './pages/HomePage';
import { PrivacyPage } from './pages/PrivacyPage';
import { StatusPage } from './pages/StatusPage';
import { TOSPage } from './pages/TermsOfServicePage';


export function AppRouter() {
  return (
    <BrowserRouter>
      <RoutesSwitch>
        <Route path={Routes.HOME} element={<HomePage/>}/>
        <Route path={Routes.AUTH_LOGIN} element={<AuthLogin/>}/>
        <Route path={Routes.AUTH_LOGIN_CALLBACK} element={<AuthLoginCallbackPage/>}/>
        <Route path={Routes.AUTH_REDIRECT} element={<AuthRedirectPage/>}/>
        <Route path={Routes.COMMANDS} element={<CommandsPage/>}/>
        <Route path={Routes.FREQUENTLY_ASKED_QUESTIONS} element={<FAQPage/>}/>
        <Route path={Routes.FOXBOT} element={<FoxBot/>}/>
        <Route path={Routes.INVITE} element={<Invite/>}/>
        <Route path={Routes.LEGAL_PRIVACY} element={<PrivacyPage/>}/>
        <Route path={Routes.LEGAL_TERMS_OF_SERVICE} element={<TOSPage/>}/>
        <Route path={Routes.STATUS} element={<StatusPage/>}/>
        <Route path={Routes.SUPPORT_INVITE} element={<SupportInvite/>}/>
        <Route path='*' element={<ErrorPage/>}/>
      </RoutesSwitch>
    </BrowserRouter>
  );
}


function AuthLogin() {
  const user = useStoreUserMe((state) => state.user);

  if (!user) {
    window.location.replace(ApiEndpoints.PATH + ApiEndpoints.AUTH_LOGIN);
    return <Outlet/>;
  }
  return <Navigate to={Routes.HOME}/>;
}


function AuthRedirectPage() {
  if (window.location.hash) {
    const parameters = new URLSearchParams(window.location.hash.substring(1));
    const state = parameters.get('state');
    if (state) {
      window.plausible(PlausibleGoals.BOT_ADDED, {props: {from: state}});
      switch (state) {
        case 'discordbotlist.com': {
          // @ts-ignore
          if(!window.dbl){window.dbl={queue:[]};}
          // @ts-ignore
          window.dbl.oauth=function(botID,endpoint){if(!window.fetch){return;}
          // @ts-ignore
          window.fetch(endpoint||'https://discordbotlist.com/api/v1/magic',{method:'post',headers:{'Content-Type':'application/json'},body:JSON.stringify({type:'bot.oauth',botID})});window.fetch('https://magic.prism.gg/api/event',{method:'post',headers:{'Content-Type':'application/json'},body:JSON.stringify({name:'Bot OAuth',url:window.location.href,domain:'discordbotlist.com',props:{id:botID}})});}
          // @ts-ignore
          window.dbl.queue.forEach(function(job){window.dbl[job[0]].apply(null,job[1])();});
          // @ts-ignore
          window.dbl.oauth('439205512425504771');
        }; break;
      }
    }
  }
  return <Navigate to={Routes.COMMANDS}/>;
}


function FoxBot() {
  window.location.replace('https://discordapp.com/users/66078337084162048');
  return <Outlet/>;
}


function Invite() {
  window.location.replace(ApiEndpoints.PATH + ApiEndpoints.HELP_DISCORD_BOT);
  return <Outlet/>;
}


function SupportInvite() {
  window.location.replace(ApiEndpoints.PATH + ApiEndpoints.HELP_DISCORD_SERVER);
  return <Outlet/>;
}
